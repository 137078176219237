import React from 'react';
import { Card as ReactBootstrapCard } from 'react-bootstrap';

import cx from 'classnames';

import LoadingIcon from 'components/Icons/Loading/Loading';

type ReactCardProps = {
  Body: typeof ReactBootstrapCard.Body;
  Header: typeof ReactBootstrapCard.Header;
};

type CardProps = {
  className?: string;
  loading?: boolean;
  loadingVariant?: string;
};

const Card: React.FC<CardProps> & ReactCardProps = ({
  className,
  loading,
  children,
  loadingVariant = 'white',
  ...props
}) => {
  return (
    <ReactBootstrapCard
      className={cx(
        'position-relative',
        { 'overflow-hidden': loading },
        className,
      )}
      {...props}
    >
      {loading && (
        <div
          className={`left-0 top-0 position-absolute bg-${loadingVariant}-70 d-flex justify-content-center align-items-center w-100 h-100 z-1000`}
        >
          <LoadingIcon height="4rem" width="4rem" />
        </div>
      )}
      {children}
    </ReactBootstrapCard>
  );
};

Card.Body = ReactBootstrapCard.Body;
Card.Header = ReactBootstrapCard.Header;
export default Card;
