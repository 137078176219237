import Head from 'next/head';
import React, { FC } from 'react';

interface IProps {
  title?: string;
  description?: string;
  thumbnailURL?: string;
  url?: string;
  published_time?: string;
  keywords?: string;
  canonical_url?: string;
}
const HeadWithSocialMedia: FC<IProps> = ({
  title,
  description,
  thumbnailURL,
  url,
  published_time,
  keywords,
  canonical_url,
  children,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta key="title" content={title} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="description" content={description} />

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={thumbnailURL} />

      <meta
        name="twitter:title"
        content={`Surf Life Saving Foundation - ${title}`}
      />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={thumbnailURL} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={thumbnailURL} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Surf Life Saving Foundation" />
      {published_time && (
        <meta property="article:published_time" content={published_time} />
      )}
      {published_time && (
        <meta property="article:modified_time" content={published_time} />
      )}
      {canonical_url && <link rel="canonical" href={canonical_url} />}
      {children}
    </Head>
  );
};

export default HeadWithSocialMedia;
