import React, { HTMLAttributes } from 'react';

import cx from 'classnames';
import currencyFormat from 'utils/curencyFormat';

import { IDonationOption } from 'models/donationOption';

import s from './DonationOptionVerticalTile.module.scss';

interface DonationOptionVerticalTileProps extends HTMLAttributes<HTMLElement> {
  donationCampaignOption: IDonationOption;
  imageClassName?: string;
}
const DonationOptionVerticalTile: React.FC<DonationOptionVerticalTileProps> = ({
  donationCampaignOption,
  imageClassName,
  className,
  ...props
}) => {
  return (
    <div {...props} className={cx(className, s.root, 'd-flex flex-column')}>
      {donationCampaignOption.image && (
        <div className="flex-grow-1 overflow-hidden">
          <img
            className={cx('d-block w-100 h-100 mb-3', imageClassName)}
            loading="lazy"
            src={donationCampaignOption.image.url}
            alt={donationCampaignOption.image_alt}
          />
        </div>
      )}
      <h3 className="text-black text-center">
        {donationCampaignOption.heading}
      </h3>
      <h3 className="text-danger text-center">
        {currencyFormat(donationCampaignOption.amount).replace(/.00$/, '')}
      </h3>
    </div>
  );
};

export default DonationOptionVerticalTile;
