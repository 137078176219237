import React from 'react';

type LoadingIconProps = {
  height?: string;
  width?: string;
};
const LoadingIcon: React.FC<LoadingIconProps> = (
  { height, width } = { height: '2em', width: '2em' },
) => {
  return (
    <div>
      <svg height={height} width={width} viewBox="0 0 468.293 468.293">
        <g>
          <path
            fill="#ffd520"
            d="M349.006,180.535l104.214-29.09C430.289,90.737,382.873,42.081,322.997,17.498l-29.223,104.688
        C317.95,135.088,337.356,155.623,349.006,180.535z"
          />
          <path
            fill="#ee3124"
            d="M234.146,107.28c21.563,0,41.847,5.416,59.628,14.905l29.223-104.688
        C295.593,6.247,265.605,0,234.146,0S172.7,6.247,145.296,17.498l29.223,104.688C192.299,112.695,212.584,107.28,234.146,107.28z"
          />
          <g>
            <path
              fill="#ffd520"
              d="M174.518,122.185L145.296,17.498C85.42,42.081,38.004,90.737,15.073,151.445l104.214,29.09
          C130.937,155.623,150.343,135.088,174.518,122.185z"
            />
            <path
              fill="#ffd520"
              d="M291.149,347.372l29.164,104.481c57.93-22.949,104.529-68.295,129.056-125.408L344.2,297.089
          C331.851,318.631,313.389,336.152,291.149,347.372z"
            />
          </g>
          <g>
            <path
              fill="#ee3124"
              d="M234.146,361.013c-20.526,0-39.848-4.986-57.002-13.641L147.98,451.853
          c26.678,10.569,55.728,16.439,86.167,16.439s59.489-5.871,86.167-16.439l-29.164-104.481
          C273.994,356.026,254.672,361.013,234.146,361.013z"
            />
            <path
              fill="#ee3124"
              d="M107.28,234.146c0-19.187,4.383-37.308,12.007-53.611l-104.214-29.09
          C5.358,177.166,0,205.025,0,234.146c0,32.78,6.761,63.974,18.924,92.299l105.168-29.356
          C113.452,278.526,107.28,257.076,107.28,234.146z"
            />
            <path
              fill="#ee3124"
              d="M453.219,151.445l-104.214,29.09c7.624,16.303,12.007,34.425,12.007,53.611
          c0,22.93-6.172,44.38-16.813,62.942l105.168,29.356c12.163-28.325,18.924-59.519,18.924-92.299
          C468.293,205.025,462.935,177.166,453.219,151.445z"
            />
          </g>
          <path
            fill="#ffd520"
            d="M124.093,297.089L18.924,326.445C43.45,383.558,90.05,428.904,147.98,451.853l29.164-104.481
        C154.904,336.152,136.442,318.631,124.093,297.089z"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 234.146 234.146"
            to="360 234.146 234.146"
            dur="1200ms"
            repeatCount="indefinite"
          />
        </g>
      </svg>
      <div className="text-bold mt-2">LOADING</div>
    </div>
  );
};

export default LoadingIcon;
