import Link from 'next/link';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import DonationOptionVerticalTile from 'components/DonationOptionVerticalTile/DonationOptionVerticalTile';
import { IDonationOption } from 'models/donationOption';

export interface DonationOptionGridProps {
  donationCampaignOptions: IDonationOption[];
  donationCampaignSlug: string;
  imageClassName?: string;
}

const DonationOptionGrid: React.FC<DonationOptionGridProps> = ({
  donationCampaignOptions,
  donationCampaignSlug,
  imageClassName,
}) => {
  return (
    <Row className="justify-content-center mb-n4 mb-lg-n5">
      {!!donationCampaignOptions?.length &&
        donationCampaignOptions.map(donationCampaignOption => (
          <Col
            key={donationCampaignOption.id}
            md={6}
            lg={3}
            className="mb-4 mb-lg-5"
          >
            <Link
              prefetch={false}
              href={`/campaign/${donationCampaignSlug}/${donationCampaignOption.slug}`}
            >
              <a className="text-decoration-none">
                <DonationOptionVerticalTile
                  donationCampaignOption={donationCampaignOption}
                  imageClassName={imageClassName}
                />
              </a>
            </Link>
          </Col>
        ))}
    </Row>
  );
};

export default DonationOptionGrid;
